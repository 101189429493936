import React, {createContext, ReactElement, useContext, useEffect, useMemo} from "react";

import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {useAuth} from "./auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import {Path, PathMap} from "./PathProvider";
import axiosRetry from "axios-retry";

const AxiosContext = createContext<AxiosContextTypes | null>(null);

interface AxiosProviderProps {
    children : ReactElement
}

export type AxiosContextTypes = {
    secureApi : AxiosInstance;
    openApi : AxiosInstance;
};

export function AxiosProvider(props : AxiosProviderProps) {
    const auth = useAuth();
    const navigate = useNavigate();

    let openApi : AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_SERVER_URL ?? window.location.origin
    });

    let secureApi : AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_SERVER_URL ?? window.location.origin,
        headers: {'Authorization': 'Bearer ' + auth.user?.accessToken}
    });

    const generateNewAccessTokenAndCompleteRequest = async (requestConfig : AxiosRequestConfig) =>
        await openApi.post(
            '/api/generateNewAccessToken',
            { refreshToken: auth.user?.refreshToken }
        ).then((response : AxiosResponse) => {
            const newAccessToken : string = response.data;

            // update new access token for future requests
            auth.setNewAccessToken(newAccessToken)

            // change the authorization header on the failed request because updating the access token will
            // not make the failed request work. We just have to update it ourselves
            requestConfig.headers!['Authorization'] = `Bearer ${newAccessToken}`;

            return Promise.resolve();
        }).catch(() => {
            // There was an error getting the refresh token. Just log them out

            // we use PathMap here instead of usePath because the PathProvider is inside the AxiosProvider, thus we don't
            // have access to the usePath hook in here
            navigate(PathMap.get(Path.Login)?.path ?? '',{ state: { warningMessage : 'Session Expired'} });
        });

    /// Whenever any request made with the secureApi returns a 401, generateNewAccessTokenAndCompleteRequest will run
    // and do exactly what it says.
    axiosRetry(
        secureApi,
        {
            retries: 3,
            retryDelay: axiosRetry.exponentialDelay,
            retryCondition(error: AxiosError) {
                return error.response?.status == 401
            },
            onRetry(retryCount: number, error : AxiosError, requestConfig : AxiosRequestConfig) {
                generateNewAccessTokenAndCompleteRequest(requestConfig)
            }
        }
    );

    // If ever the access token changes (which it does), update the secureApi headers
    useEffect(() => {
        secureApi.defaults.headers.common['Authorization'] = `Bearer ${auth?.user?.accessToken}`
    }, [auth?.user?.accessToken]);

    const value = useMemo<AxiosContextTypes>(
        () => ({
            secureApi,
            openApi
        }),
        [auth.user?.accessToken]
    );

    return <AxiosContext.Provider value={value}>{props.children}</AxiosContext.Provider>;
}

export const useAxios = () => {
    return useContext(AxiosContext);
};